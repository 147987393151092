import { ChangeEvent, FC, useEffect, useState, useCallback, useMemo } from 'react';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import { ModuleTemplate, ModuleType, ModuleTypeKeys } from '../../models/Module';
import TemplateModuleService from '../../services/TemplateModuleService';
import { Option } from '../../components/Option';
import ContextMenu, { ContextMenuItem } from '../../components/shared/ContextMenu';
import Button, { ButtonType } from '../../components/shared/form-control/Button';
import DropdownSelect from '../../components/shared/form-control/DropdownSelect';
import { SearchInput } from '../../components/shared/form-control/SearchInput';
import PageLoader from '../../components/shared/page-loader/PageLoader';
import { Heading, HeadingSize } from '../../components/shared/text/Heading';
import LockIcon from '../../components/shared/icon/LockIcon';
import DataRow from '../../components/shared/data-grid/DataRow';
import IconRender, { PlatformIcons } from '../../components/shared/icon/IconRender';
import { useTranslation } from 'react-i18next';
import LanguageUtils from '../../utils/LanguageUtils';
import { useRecoilValue } from 'recoil';
import { currentClientAtom, currentTenantIdAtom, myClientsAtom } from '../../recoil/atoms/Clients';
import ClientTemplateModuleService from '../../services/ClientTemplateModuleService';
import UsersGroupIcon from '../../components/shared/icon/UsersGroupIcon';
import { Roles } from '../../models/Role';
import InfoIcon from '../../components/shared/icon/InfoIcon';
import IconPicker from '../../components/shared/form-control/IconPicker';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import usePermissions from '../../hooks/permissions/usePermissions';
import Loader from '../../components/shared/Loader';
import Checkbox from '../../components/shared/form-control/Checkbox';
import StandardModal from '../../components/shared/modal/variants/StandardModal';
import { ModalContext } from '../../contexts/ModalContext';
import { Feature, useFeatureFlags } from '../../contexts/FeatureFlagContext';
import TranslatableInput from '../../components/shared/form-control/TranslatableInput';
import { Translations } from '../../models/Translation';
import FilterTag, { FilterSelectMode } from '../../components/shared/tags/FilterTag';
import ModuleUtils from '../../utils/ModuleUtils';
import ManageClassesWizard, { ManageClassesWizardStep, manageClassesWizardSteps } from '../../components/documents/manage-classes/ManageClassWizard';
import { ClientModuleTemplateDefault } from '../../models/ClientModuleDefaults';
import User from '../../models/User';
import ClientService from '../../services/ClientService';

const ModuleTemplateList: FC = () => {
  const { t, i18n } = useTranslation(['organisation', 'common']);
  const { featureFlags } = useFeatureFlags();
  const currentClient = useRecoilValue(currentClientAtom);
  const currentTenantId = useRecoilValue(currentTenantIdAtom);
  const [loading, setLoading] = useState(false);
  const [modules, setModules] = useState<ModuleTemplate[]>([]);
  const [serviceProviderModulesOptions, setServiceProviderModuleOptions] = useState<Option<string, string>[]>([]);
  const [serviceProviderModules, setServiceProviderModule] = useState<ModuleTemplate[]>([]);
  const [selectedServiceProviderModule, setSelectedServiceProviderModule] = useState<string>('');
  const [searchValue, setSearchValue] = useState('');
  const [modalLanguage, setModalLanguage] = useState(i18n.language);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newModule, setNewModule] = useState<ModuleTemplate | null>(null);
  const [selectedTemplateDefault, setSelectedTemplateDefault] = useState<ClientModuleTemplateDefault | null>(null);
  const [activeManageWizardStep, setActiveManageWizardStep] = useState<ManageClassesWizardStep>(manageClassesWizardSteps[3]);
  const [clientUsers, setClientUsers] = useState<User[]>([]);

  const [showAddExistingModal, setShowAddExistingModal] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showManageClassModal, setShowManageClassModal] = useState(false);
  const [editingModule, setEditingModule] = useState<ModuleTemplate | null>(null);
  const hasPermission = usePermissions();

  const [sharingModule, setSharingModule] = useState<ModuleTemplate | null>(null);
  const [sharingWithListOriginal, setSharingWithListOriginal] = useState<string[] | null>(null);
  const [sharingWithList, setSharingWithList] = useState<string[] | null>(null);
  const myClients = useRecoilValue(myClientsAtom);
  const currentTenant = useRecoilValue(currentTenantIdAtom);

  const toasts = useToasts();

  const templateService = useMemo(() => {
    if (currentClient) {
      return new ClientTemplateModuleService(currentClient.id);
    }
    return TemplateModuleService;
  }, [currentClient]);

  useEffect(() => {
    if (!showEditModal) {
      setModalLanguage(i18n.language);
    }
  }, [i18n.language, showEditModal]);

  const [typeFilters, setTypeFilters] = useState<Option<(typeof ModuleType)[keyof typeof ModuleType], boolean>[]>([
    {
      id: ModuleType.Default,
      text: t(ModuleTypeKeys[ModuleType.Default]),
      value: true,
    },
    {
      id: ModuleType.Document,
      text: t(ModuleTypeKeys[ModuleType.Document]),
      value: true,
    },
  ]);

  const applyTypesFilter = (filters: Option<(typeof ModuleType)[keyof typeof ModuleType], boolean>[]) => {
    setTypeFilters(filters);
  };

  const loadTemplates = useCallback(() => {
    setLoading(true);
    templateService
      .getTemplates(
        undefined,
        undefined,
        typeFilters
          .filter((filter) => filter.value)
          .map((filter) => filter.id)
          .flat(),
      )
      .then((res) => {
        setModules(res.data);
        setLoading(false);
      });
  }, [templateService, typeFilters]);

  useEffect(() => {
    loadTemplates();
  }, [loadTemplates]);

  useEffect(() => {
    TemplateModuleService.getTemplates(
      false,
      undefined,
      typeFilters
        .filter((filter) => filter.value)
        .map((filter) => filter.id)
        .flat(),
    ).then((res) => {
      setServiceProviderModule(res.data);
      setServiceProviderModuleOptions(
        res.data
          .filter((module) => !modules.find((x) => x.id === module.id))
          .map((module) => ({
            id: module.id,
            value: module.id,
            text: `${LanguageUtils.getTranslation('name', module.translations, i18n.language)} ${
              module?.type === ModuleType.Document ? `(${t(ModuleTypeKeys[module?.type || ModuleType.Default])})` : ''
            }`,
          })),
      );
    });
  }, [i18n.language, modules, t, typeFilters]);

  const getUsers = useCallback(() => {
    if (currentClient) {
      ClientService.getUsers().then((res) => {
        setClientUsers(res);
      });
    }
  }, [currentClient]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const onSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const searchTerm = searchValue.toLowerCase();
  const filteredModules = modules.filter(
    (module) =>
      !searchTerm ||
      LanguageUtils.getTranslation('name', module.translations).toLowerCase().indexOf(searchTerm) > -1 ||
      LanguageUtils.getTranslation('description', module.translations).indexOf(searchTerm) > -1,
  );

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) {
      return;
    }

    const activeIndex = modules.findIndex((x) => x.id === active.id);
    const overIndex = modules.findIndex((x) => x.id === over.id);
    const newModuleOrder = arrayMove(modules, activeIndex, overIndex);
    setModules(newModuleOrder);
    templateService.sortOrder(newModuleOrder.map((x) => x.id));
  };

  const lockModule = useCallback(
    (module: ModuleTemplate) => {
      templateService.lockTemplate(module.id, module).then(() => {
        toasts.addToast({ title: t('organisation:modules.toasters.module-locked.title'), type: ToastType.SUCCESS, expiresInMs: 5000 });
        loadTemplates();
      });
    },
    [loadTemplates, t, templateService, toasts],
  );

  const unlockModule = useCallback(
    (module: ModuleTemplate) => {
      templateService.unlockTemplate(module.id, module).then(() => {
        toasts.addToast({ title: t('organisation:modules.toasters.module-unlocked.title'), type: ToastType.SUCCESS, expiresInMs: 5000 });
        loadTemplates();
      });
    },
    [loadTemplates, t, templateService, toasts],
  );

  const removeModule = useCallback(
    (moduleId: string) => {
      templateService.removeTemplate(moduleId).then(() => {
        toasts.addToast({ title: t('organisation:modules.toasters.module-removed.title'), type: ToastType.SUCCESS, expiresInMs: 5000 });
        loadTemplates();
      });
    },
    [loadTemplates, t, templateService, toasts],
  );

  const editModule = useCallback(
    (module: ModuleTemplate) => {
      setEditingModule(module);
      if (module.type === ModuleType.Default) {
        setShowEditModal(true);
      } else {
        setShowManageClassModal(true);
        setActiveManageWizardStep(manageClassesWizardSteps[6]);
        if (currentClient) {
          new ClientTemplateModuleService(currentClient.id).getTemplatesConfigurations(ModuleType.Document, module.id).then((res) => {
            setSelectedTemplateDefault(res.data[0] || null);
          });
        } else {
          setSelectedTemplateDefault({ templateModule: module, clientModuleId: '', nextDocumentNumber: 0, clientModuleName: '' });
        }
      }
    },
    [currentClient],
  );

  const onEditModalClose = useCallback(() => {
    setEditingModule(null);
    setShowEditModal(false);
  }, []);

  const addModuleValue = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (key: keyof ModuleTemplate, value: any) => {
      setNewModule(
        (prev) =>
          prev && {
            ...prev,
            translations: {
              ...(prev.translations || {}),
              [modalLanguage]: {
                ...((prev.translations || {})[modalLanguage] || {}),
                [key]: value,
              },
            },
          },
      );
    },
    [modalLanguage],
  );

  const editModuleValue = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (key: keyof ModuleTemplate, value: any) => {
      setEditingModule(
        (prev) =>
          prev && {
            ...prev,
            translations: {
              ...(prev.translations || {}),
              [modalLanguage]: {
                ...((prev.translations || {})[modalLanguage] || {}),
                [key]: value,
              },
            },
          },
      );
    },
    [modalLanguage],
  );

  const saveEditedModule = useCallback(() => {
    if (!editingModule) {
      return;
    }

    templateService
      .updateTemplate(editingModule.id, editingModule)
      .then(() => {
        setModules((prev) => prev.map((x) => (x.id === editingModule.id ? editingModule : x)));
        onEditModalClose();
      })
      .catch((e) => {
        toasts.addToast({
          title: t('organisation:modules.toasters.save-error.title'),
          description: e.meta.message,
          type: ToastType.ERROR,
          expiresInMs: 5000,
        });
      });
  }, [editingModule, onEditModalClose, t, templateService, toasts]);

  const setNewModuleTranslations = useCallback((value: Translations) => {
    setNewModule((prev) => prev && { ...prev, translations: value });
  }, []);

  const setEditingModuleTranslations = useCallback((value: Translations) => {
    setEditingModule((prev) => prev && { ...prev, translations: value });
  }, []);

  useEffect(
    function updateModuleShares() {
      if (!featureFlags.moduleSharing) {
        return;
      }
      if (!sharingModule) {
        setSharingWithList(null);
        setSharingWithListOriginal(null);
        return;
      }

      TemplateModuleService.getShares(sharingModule.id).then((res) => {
        setSharingWithList(res.data);
        setSharingWithListOriginal(res.data);
      });
    },
    [featureFlags.moduleSharing, sharingModule],
  );

  const myTenants = useMemo(() => myClients.filter((x) => x.isTenant && x.id !== currentTenant), [currentTenant, myClients]);

  const contextItemsFor = useCallback(
    (module: ModuleTemplate) => {
      const result: ContextMenuItem[] = [];

      if (!currentClient || module.isOwnedByClient) {
        result.push({
          title: t('organisation:modules.list.actions.edit'),
          onClick: () => editModule(module),
        });
      }

      if (module.isLocked || (!module.isEnabled && currentClient)) {
        result.push({
          title: t('organisation:modules.list.actions.unlock'),
          onClick: () => unlockModule(module),
        });
      } else {
        result.push({
          title: t('organisation:modules.list.actions.lock'),
          onClick: () => lockModule(module),
        });
      }

      if (currentClient && !module.isOwnedByClient && hasPermission(Roles.Consultant)) {
        result.push({
          title: t('organisation:modules.list.actions.remove'),
          onClick: () => removeModule(module.id),
        });
      }

      if (!currentClient && !module.isLocked && myTenants.length > 0 && featureFlags.moduleSharing) {
        result.push({
          title: t('organisation:modules.list.actions.share'),
          onClick: () => setSharingModule(module),
        });
      }

      return result;
    },
    [featureFlags.moduleSharing, currentClient, editModule, hasPermission, lockModule, myTenants.length, removeModule, t, unlockModule],
  );

  const generateNewTemplate = useCallback(
    (type: ModuleType = ModuleType.Default) => {
      return ModuleUtils.NewTemplate(currentClient?.id, type, modules.length, t('organisation:modules.untitled-section'));
    },
    [currentClient?.id, modules.length, t],
  );

  const onCreateModalClose = () => {
    setShowCreateModal(false);
  };

  const onCreateModalOpen = () => {
    setShowCreateModal(true);
    setNewModule(generateNewTemplate());
  };

  const onCreateClassModalOpen = () => {
    setShowManageClassModal(true);
    setNewModule(generateNewTemplate(ModuleType.Document));
  };

  const onCreateClassModalClose = () => {
    setShowManageClassModal(false);
    setSelectedTemplateDefault(null);
  };

  const onAddExistingModalOpen = () => {
    setShowAddExistingModal(true);
  };

  const onAddExistingModalClose = () => {
    setShowAddExistingModal(false);
    setSelectedServiceProviderModule('');
  };

  const createNewModule = () => {
    if (!newModule) {
      return;
    }
    templateService.createTemplate(newModule).then(() => {
      onCreateModalClose();
      loadTemplates();
    });
  };

  const importServiceProviderModule = () => {
    if (!selectedServiceProviderModule) {
      return;
    }

    const isDocumentType = serviceProviderModules.find((x) => x.id === selectedServiceProviderModule)?.type === ModuleType.Document;

    (templateService as ClientTemplateModuleService)[isDocumentType ? 'importTemplatesAndInstantiate' : 'importTemplate']
      .bind(templateService)(selectedServiceProviderModule)
      .then(() => {
        onAddExistingModalClose();
        loadTemplates();
      });
  };

  const newModuleIconText = useMemo(
    () =>
      newModule?.iconCode
        ?.replace(/Icon$/, '')
        .match(/[A-Z][a-z]+/g)
        ?.join(' ') || '',
    [newModule?.iconCode],
  );

  const onShareWithChanged = useCallback((clientId: string, active: boolean) => {
    if (active) {
      setSharingWithList((prev) => prev && [...prev, clientId]);
    } else {
      setSharingWithList((prev) => prev && prev.filter((x) => x !== clientId));
    }
  }, []);

  const saveSharedModules = useCallback(() => {
    if (!sharingModule?.id) {
      return;
    }

    const addedClientIds = sharingWithList?.filter((x) => !sharingWithListOriginal?.includes(x)) ?? [];
    const removedClientIds = sharingWithListOriginal?.filter((x) => !sharingWithList?.includes(x)) ?? [];

    toasts.addToast({ title: t('organisation:modules.toasters.sharing.title'), type: ToastType.SUCCESS, expiresInMs: 7000 });
    for (const clientId of addedClientIds) {
      TemplateModuleService.shareWithClient(sharingModule.id, clientId);
    }

    for (const clientId of removedClientIds) {
      TemplateModuleService.revokeSharingWithClient(sharingModule?.id, clientId);
    }

    setSharingModule(null);
  }, [sharingModule?.id, sharingWithList, sharingWithListOriginal, t, toasts]);

  const moduleTemplateClickUrl = useCallback(
    (module: ModuleTemplate) => {
      const baseRoute = currentClient ? `/clients/${currentClient.id}` : `/builder/${currentTenantId}`;
      const sectionId = module.sections[0]?.id;

      if (module.type === ModuleType.Default) {
        return !currentClient || !!module.clientId ? `${baseRoute}/module-builder/${module.id}/sections` : undefined;
      } else {
        return !currentClient || !!module.clientId ? `${baseRoute}/module-builder/${module.id}/forms/${sectionId}` : undefined;
      }
    },
    [currentClient, currentTenantId],
  );

  const onClassCreated = useCallback(() => {
    setShowManageClassModal(false);
    loadTemplates();
  }, [loadTemplates]);

  const onClassUpdated = useCallback((value: ClientModuleTemplateDefault) => {
    setSelectedTemplateDefault(null);
    setModules((prevItems) => prevItems.map((item) => (item.id === value.templateModule.id ? value.templateModule : item)));
  }, []);

  return (
    <PageLoader loading={loading}>
      <div className="flex h-full flex-col pt-6" data-cy="modules-list">
        <div className="flex justify-between">
          <Heading size={HeadingSize.H3}>{t('organisation:tabs.modules')}</Heading>
          <div className="flex items-center gap-4">
            <div className="w-80">
              <SearchInput data-cy="search-modules" placeholder={t('common:list.filter.search')} value={searchValue} onChange={onSearchValueChange} />
            </div>
            {currentClient && hasPermission(Roles.Consultant) && (
              <Button data-cy="create-new-module" onClick={onAddExistingModalOpen} type={ButtonType.SECONDARY}>
                {t('organisation:modules.import')}
              </Button>
            )}
            <ContextMenu
              items={[
                { title: t('organisation:modules.create-module'), onClick: onCreateModalOpen },
                {
                  title: t('organisation:modules.create-class'),
                  onClick: onCreateClassModalOpen,
                },
              ]}
              type="button"
              selectedButtonTitle={t('organisation:modules.create')}
            />
          </div>
        </div>
        <div className="bg-background-1 h-full flex-grow py-8 pt-4">
          <FilterTag
            tag={{
              id: 'type',
              text: t(`common:list.filter.type`, { count: typeFilters.filter((filter) => filter.value)?.length || 0 }),
              value: 'type',
            }}
            onFiltersChange={applyTypesFilter}
            mode={FilterSelectMode.Multi}
            options={typeFilters}
          />
          <div className="flex items-center" data-cy="list-header">
            <div className="mx-1 w-16">{/* SPACER */}</div>
            <div className="w-1/3 px-4">
              <IconRender type={PlatformIcons.AUDIT_ICON} className="invisible mr-2 h-8 w-8" /> <div className="inline-block w-8 text-center">#</div>{' '}
              {t('organisation:modules.list.name')}
            </div>
            <div className="w-2/3 px-4"> {t('organisation:modules.list.description')}</div>
            <div className="w-48 px-4"> {t('organisation:modules.list.type')}</div>
            <div className="mx-1 w-28">{/* SPACER */}</div>
          </div>
          {filteredModules.length === 0 && (
            <div data-cy="task-list-empty" className="flex h-full justify-center ">
              <div className="mt-36 text-center">
                <InfoIcon className="bg-primary-1 text-primary-1 my-2 h-16 w-16 rounded-full bg-opacity-10 p-4" />
                <Heading size={HeadingSize.H3} className="my-4">
                  {t('organisation:modules.list.empty.title')}
                </Heading>
                <div className="text-dpm-20">{t('organisation:modules.list.empty.description')}</div>
              </div>
            </div>
          )}
          <div data-cy="draggable-area">
            <DndContext onDragEnd={onDragEnd} modifiers={[restrictToVerticalAxis]}>
              <SortableContext items={filteredModules.map((x) => x.id)} strategy={verticalListSortingStrategy}>
                {filteredModules.map((module, index) => (
                  <DataRow
                    data-cy={`module-${index}`}
                    key={module.id}
                    url={moduleTemplateClickUrl(module)}
                    contextMenuItems={contextItemsFor(module)}
                    isSortingDisabled={false}
                    sortableId={module.id}
                    forceArrowSpacing={!!currentClient && !module.clientId}
                  >
                    <div className="flex w-1/3 items-center px-4 py-2 text-black">
                      {!module.isOwnedByClient && currentClient && <UsersGroupIcon className="text-gray-1 mr-2 h-6 w-6 flex-shrink-0" />}
                      {module.isOwnedByClient && <div className="mr-2 h-6 w-6" />}
                      <IconRender type={module.iconCode} className="text-gray-1 mr-2 h-6 w-6 flex-shrink-0" />{' '}
                      <div data-cy="module-number" className="inline-block w-8 text-center">
                        {index + 1}
                      </div>
                      <span className="font-medium" data-cy="module-name">
                        {LanguageUtils.getTranslation('name', module.translations)}
                      </span>
                    </div>
                    <div className="w-2/3 overflow-ellipsis px-4 py-2">
                      <span data-cy="module-description">{LanguageUtils.getTranslation('description', module.translations)}</span>
                    </div>
                    <div className="w-48 overflow-ellipsis px-4 py-2">{t(ModuleTypeKeys[module.type || ModuleType.Default])}</div>
                    <LockIcon
                      data-cy="module-locked"
                      className={`h-4 w-4 self-center ${!(module.isLocked || (!module.isEnabled && currentClient)) && 'invisible'}`}
                    />
                  </DataRow>
                ))}
              </SortableContext>
            </DndContext>
          </div>
        </div>
      </div>
      <ModalContext.Provider value={{ open: showCreateModal, onClose: onCreateModalClose, modalWidth: 'w-2/5' }}>
        <StandardModal
          title={t('organisation:modules.modals.create.title')}
          cancelButtonTitle={t('organisation:modules.modals.create.buttons.cancel')}
          confirmButtonTitle={t('organisation:modules.modals.create.buttons.create')}
          confirmDisabled={!LanguageUtils.getTranslation('name', newModule?.translations ?? {}).trim()}
          onCancelClick={onCreateModalClose}
          onConfirmClick={createNewModule}
        >
          <TranslatableInput
            data-cy="new-module-name"
            placeholder={t('organisation:modules.modals.create.module-name')}
            label={t('organisation:modules.modals.create.module-name')}
            aria-label={t('organisation:modules.modals.create.module-name')}
            translationKey="name"
            translations={newModule?.translations || {}}
            onTranslationsChange={setNewModuleTranslations}
            maxLength={150}
          />

          <TranslatableInput
            data-cy="new-module-description"
            className="w-full resize-none"
            placeholder={t('organisation:modules.modals.create.module-description')}
            label={t('organisation:modules.modals.create.module-description')}
            aria-label={t('organisation:modules.modals.create.module-description')}
            translationKey="description"
            translations={newModule?.translations || {}}
            onTranslationsChange={setNewModuleTranslations}
            maxLength={1000}
            inputType={'multiline'}
          />

          <div className="text-dpm-12 mt-4">{t('organisation:modules.modals.create.module-icon')}</div>
          <IconPicker
            data-cy="new-module-icon"
            options={Object.values(PlatformIcons).map((iconValue) => ({ id: iconValue, text: iconValue, value: iconValue }))}
            placeholder={t('organisation:modules.modals.create.module-icon')}
            value={newModule ? { id: newModule.iconCode, text: newModuleIconText, value: newModule.iconCode } : undefined}
            onChange={(option) => addModuleValue('iconCode', option.id as PlatformIcons)}
          />
        </StandardModal>
      </ModalContext.Provider>

      <ModalContext.Provider value={{ open: showEditModal, onClose: onEditModalClose, modalWidth: 'w-2/5' }}>
        <StandardModal
          title={t('organisation:modules.modals.edit.title')}
          cancelButtonTitle={t('organisation:modules.modals.edit.buttons.cancel')}
          confirmButtonTitle={t('organisation:modules.modals.edit.buttons.save')}
          confirmDisabled={!LanguageUtils.getTranslation('name', editingModule?.translations ?? {}).trim()}
          onCancelClick={onEditModalClose}
          onConfirmClick={saveEditedModule}
        >
          <TranslatableInput
            data-cy="new-module-name"
            placeholder={t('organisation:modules.modals.create.module-name')}
            label={t('organisation:modules.modals.create.module-name')}
            aria-label={t('organisation:modules.modals.create.module-name')}
            translationKey="name"
            translations={editingModule?.translations || {}}
            onTranslationsChange={setEditingModuleTranslations}
            maxLength={150}
          />

          <TranslatableInput
            data-cy="new-module-description"
            className="w-full resize-none"
            placeholder={t('organisation:modules.modals.create.module-description')}
            label={t('organisation:modules.modals.create.module-description')}
            aria-label={t('organisation:modules.modals.create.module-description')}
            translationKey="description"
            translations={editingModule?.translations || {}}
            onTranslationsChange={setEditingModuleTranslations}
            maxLength={1000}
            inputType={'multiline'}
          />

          <div className="text-dpm-12 mt-4">{t('organisation:modules.modals.create.module-icon')}</div>
          <IconPicker
            data-cy="new-module-icon"
            options={Object.values(PlatformIcons).map((iconValue) => ({ id: iconValue, text: iconValue, value: iconValue }))}
            placeholder={t('organisation:modules.modals.create.module-icon')}
            value={
              editingModule
                ? {
                    id: editingModule?.iconCode,
                    text:
                      editingModule?.iconCode
                        .replace(/Icon$/, '')
                        .match(/[A-Z][a-z]+/g)
                        ?.join(' ') || '',
                    value: editingModule?.iconCode,
                  }
                : undefined
            }
            onChange={(option) => editModuleValue('iconCode', option.id as PlatformIcons)}
          />
        </StandardModal>
      </ModalContext.Provider>

      <ModalContext.Provider
        value={{
          open: showManageClassModal,
          onClose: onCreateClassModalClose,
          modalWidth: 'xl:w-[800px] w-3/5',
        }}
      >
        <ManageClassesWizard
          key={selectedTemplateDefault?.templateModule.id}
          onCancel={onCreateClassModalClose}
          classes={[]}
          selectedClassDefault={selectedTemplateDefault}
          onCreated={onClassCreated}
          onUpdated={onClassUpdated}
          activeStep={activeManageWizardStep}
          clientUsers={clientUsers}
          onUserInvited={getUsers}
        />
      </ModalContext.Provider>

      <ModalContext.Provider value={{ open: showAddExistingModal, onClose: onAddExistingModalClose, modalWidth: 'w-2/5' }}>
        <StandardModal
          title={t('organisation:modules.modals.import.title')}
          cancelButtonTitle={t('organisation:modules.modals.import.buttons.cancel')}
          confirmButtonTitle={t('organisation:modules.modals.import.buttons.add')}
          confirmDisabled={!selectedServiceProviderModule}
          onCancelClick={onAddExistingModalClose}
          onConfirmClick={importServiceProviderModule}
        >
          <div className="text-dpm-12">{t('organisation:tabs.modules')}</div>
          <DropdownSelect
            data-cy="import-select"
            options={serviceProviderModulesOptions}
            value={{
              id: selectedServiceProviderModule,
              text: serviceProviderModulesOptions.find((x) => x.id === selectedServiceProviderModule)?.text || '',
              value: selectedServiceProviderModule,
            }}
            onChange={(o) => setSelectedServiceProviderModule(o.id)}
            aria-label={t('organisation:modules.modals.import.title')}
          />
        </StandardModal>
      </ModalContext.Provider>

      <Feature authorizedFlags={['moduleSharing']}>
        <ModalContext.Provider value={{ open: !!sharingModule, onClose: () => setSharingModule(null), modalWidth: 'w-2/5' }}>
          <StandardModal
            title={t('organisation:modules.modals.share.title', {
              module: LanguageUtils.getTranslation('name', sharingModule?.translations || {}),
            })}
            subTitle={t('organisation:modules.modals.share.help-text')}
            confirmButtonTitle={t('organisation:modules.modals.share.save-button')}
            onCancelClick={() => setSharingModule(null)}
            onConfirmClick={saveSharedModules}
            confirmDisabled={!sharingWithList}
          >
            <div className="flex max-h-[50vh] flex-col">
              {sharingWithList ? (
                <>
                  <div className="my-4 flex-shrink overflow-auto px-2 ">
                    {myTenants.map((x, i) => (
                      <div key={x.id} className={`flex items-center justify-between gap-4 ${i === 0 ? '' : 'border-t'}`}>
                        <div>{x.name}</div>
                        <Checkbox slider value={!!sharingWithList.find((id) => id === x.id)} onChange={(val) => onShareWithChanged(x.id, val)} />
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div className="relative min-h-12">
                  <Loader size={10} />
                </div>
              )}
            </div>
          </StandardModal>
        </ModalContext.Provider>
      </Feature>
    </PageLoader>
  );
};

export default ModuleTemplateList;
